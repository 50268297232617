@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play&family=Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Farro:wght@700&family=Itim&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  

}
body
{
   /* background-image: url("grey.png");   */
   background-color: #eeeeee;
   /* background-position: fixed;
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='163' height='163' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.05'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.05'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E"); */
}

.navbar1-body
{
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content:space-evenly;
    flex-wrap: nowrap;

   
}
.buyfromamazon{
    text-decoration: none;
    color: #000000;
}
.amazon a:hover{
    text-decoration: none;
}
.amazon-btn Button
{
   height: 25px;

  
   background-color: red !important;
}
.topbar-left .MuiButton-label
{
    font-family: inherit !important;
    font-size: 14px;
 
}

.icon
{
    font-size: 21px;
    margin-left: 5px;
    align-items: center;

}
.social-icons a
{
   color : rgb(146, 146, 146);
   font-size: 21px;
   margin: 1px;
}

.topbar-right
{
    display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}
.topbar-left
{
    display: flex;
    flex: 0.8 1;
    align-items: center;
    flex-wrap: nowrap;
   


}
.topbar-span span{
    margin-right: 8px;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif 
    

}
.topbar-span span:hover
{
    border-bottom: 2px solid black;
    cursor: pointer;
}
.hov-icon:hover
{
    transform: scale(1.15);
}

@media screen and (max-width:800px) {
    .topbar-right
    {
        display: none;
    }
    .topbar-left
    {
        justify-content: center;
    }
    .amazon-btn Button
    {
      
        font-size: 11px;
    }
    .topbar-left .MuiButton-label
    {
        font-size: 13px;
    }
    
}
.topbar-hr{
    border: 1px solid rgb(236, 236, 236);
}

.MuiTypography-body1
{
  font-family: 'Poppins' , sans-serif !important;
  font-weight: 600 !important;
}
.MuiDrawer-paperAnchorLeft 
{
  background-color: #b8c6db !important;
background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%) !important;

}
.makeStyles-list-1

{
  background-color: #b8c6db !important;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%) !important;
  
}
.navbar2-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
  

 
}
.logo img {
  height: 46px;
  width: 233px;

}
.search-bar {
  display: flex;
  flex: 0.7 1;
  justify-self: center;
  margin-right: 5px;
}
.search-input {
  height: 36px;
  border: 2px solid rgb(148, 148, 146);
  width: 80%;
  text-align: center;
  margin-left: 40px;
  border-radius: 5px;
}
.search-icon {
  padding: 3px;
  margin-left: 0px;
  align-self: center;
  position: relative;
  background-color: rgb(148, 148, 146);
  margin-top: 0px;
  transform: scale(1.53);
  border-radius: 3px;
  cursor: pointer;
  color: white;

}
.navbarmid-right {
  display: flex;
  justify-content: space-between;
  width: 10%;
}
.logo-mid {
  transform: scale(1.8);
}

.hr-mid {
  border: 1px solid rgb(236, 236, 236);
  margin-top: 20px;
}

/* Dropdown Button */
.dropbtn {
  cursor: pointer;
  margin: 0 30px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  top: 35px;
  right: 15px;
  width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
  cursor: pointer;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #ddd;
}

.user-details {
  font-size: 12px;
  padding: 5px 16px;
}

.menu-btn {
  display: none;
}
.cart-icon span {
  font-size: 14px;
  font-style: italic;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 500;

}

.amazon
{
  background-color: rgb(236, 207, 60) !important;
  color:black !important ;
  font-weight: 1000 !important;
  font-family: 'Farro', sans-serif  !important;
  font-size: 10px !important;
}




@media screen and (max-width: 768px) {
  .navbar2-body {
    justify-content: space-around;
    margin-top: 10px;

  }

  .logo img {
    width: 190px;
    height: 35px;

    justify-content: center;
    margin-right: 15px;
  }
  .search-bar {
    display: none;
  }
  .logo-mid {
    transform: scale(1.5);
    margin-right: 2px;
  }
  .menu-btn {
    display: block;
  }
  .dropdown {
    display: none;
  }
  .cart-icon span {
    font-size: 11px;
  }
  .hr-mid {
    margin-top: 10px;
  }
  .MuiBadge-root {
    margin-right: 9px;
  }
}

.navbar3-body {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 60px;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 3;
  background-color: rgb(31, 37, 56);
  
  
}
.navbar3-body span {
  font-size: 16px;
  color:white;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-decoration: none;

}
.navbar3-body span:hover {

  cursor: pointer;
  border-bottom: 2px solid white;
}
.hr-bottom {
  margin-top: 0px;
  border: 1px solid rgb(236, 236, 236);
  margin-bottom: 0px;
}
.search-bar-bottom {
  display: none;

  justify-self: center;
  margin-right: 25px;
}
.search-input-bottom {
  height: 34px;
  border: 1px solid rgb(142 142 142);
  width: 80%;
  text-align: center;
  border-radius: 5px;
}
.search-icon-bottom {
  padding: 4px;
  margin-left: 0px;
  align-self: center;
  position: relative;
  background-color: rgb(180, 211, 80);
  margin-top: 0px;
  transform: scale(1.46);
  border-radius: 3px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar3-body {
    display: none;
  }
  .search-bar-bottom {
    display: flex;
    justify-content: center;
  }
}

.navbar4-body
{
   width: 100%;
  background-color:  rgb(31, 37, 56);

  color: rgb(224, 193, 20);
 
}
.navbar4-body h5{
    font-size: 12px;
    text-align: center;
    font-weight: 800;
    font-family: 'Farro', sans-serif;
    padding: 3px;
    margin-bottom: 0;
    
}
@media (min-width: 1000px)
{
   .navbar4-body
   {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 60px;
      z-index: 3;

   }
}
/* .makeStyles-container-26 {

max-width: 240px !important;
} */
body{
	line-height: 1.5;
	font-family: "Mr Eaves XL Modern", sans-serif

}

.footer__body{
	width: 100%;
	bottom: 0;
}
.footer{
	margin:0;
	padding:0;
	box-sizing: content-box !important;
	
}

.container__footer{
	max-width: 1170px;
	margin:auto;
}
.row__footer{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
	margin-left: 0px;
}
.footer{
	background-image: url(/static/media/footer.9c6b860e.png);
    padding: 70px 0;

	
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 13px;
	color: white;
	font-family: "Mr Eaves XL Modern", sans-serif;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 600;
	position: relative;
	text-align: left;
	margin-right: 30px;
}



.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}

.footer-col ul li a{
	font-size: 11px;
	text-transform: capitalize;
	color:white;
	text-decoration: none;
	font-weight: 400;
	text-align: left;
	display: block;
	transition: all 0.3s ease;
	font-family: "Mr Eaves XL Modern", sans-serif;
	
}
.footer-col ul li a:hover{
	color:white;

}
.footer-col .social-links a{
    text-decoration: none;
}

.footer-col .social-links a img{
   
	display: inline-block;
	height: 40px;
	width: 40px;
	margin:0 10px 10px 0;	text-align: center;
	border-radius: 50%;
	
	transition: all 0.5s ease;
}


.footer_p{
	color:white;
   font-size: 11px;
	font-family: "Mr Eaves XL Modern", sans-serif;


}
.footer__p{

     font-size: 14px;
	 border-bottom: 1px solid orange;
	 width:120px;
 }
.city__p{
    color:white;
    font-size: 11px;
	font-family: "Mr Eaves XL Modern", sans-serif;

}
.payment__image{
	width: 247px;
    height: 160px;
}

.footer__logo{
	height: 20%;
	width: 100%;
}
 

/*responsive*/
@media(max-width: 768px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}


.need-help-ul
{
	margin-left: 0px;
}

.all-rights
{
	margin-right: 0px;
	border-bottom: 1px solid orange;
}
ul{
	-webkit-padding-start: 0px !important;
	        padding-inline-start: 0px !important;

}
.copy
{
	font-size: 12px !important;
	color: white;
	text-align: center;
}


.social-icons-footer a
{
   color : rgb(119, 199, 252);
   font-size: 21px;
   margin: 3px;
}
.social-icons-footer  a:hover
{
	transform: scale(1.4);
}


.youtube
{
	color: red;
}
.facebook
{
color: rgb(57, 159, 255);
}
.instagram
{
	color: white;
}
.linkedin
{
	color: rgb(73, 158, 255);
}
.google 
{
	color: white;
}
.pinterest
{
	color: rgb(255, 0, 85);
}
.ad-banner
{
    height: 100px;
   width: 100%;
    object-fit: cover;
    margin-bottom: 10px;
}
.home1-body
{  width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
   margin-bottom: 0px;
   /* margin-top: -70px; */
  
 color: white;
background-color: rgb(31, 37, 56);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-repeat: no-repeat;
  background-size: auto ;
  padding: 40px;
}
.home1-right img
{
    object-fit: contain;
    

}
.home1-left
{
width: 30%;

/* margin-top: -46%; */

  padding-bottom: 40px;
}
.home1-right
{
    /* margin-top: -46%; */
  
}
.home1-heading
{  
   
    font-size: 22px;
    border-bottom: 1px solid black;
    margin-bottom: 25px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin-left: 10px;
}

.home1-p
{
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 30px;
    text-align: left;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    padding-left: 0px;

}
@media (max-width:815px)
{
   .home1-body
   {
       margin-top: 0px;
       padding: 10px;

   }
    .home1-left
    {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
      

    }
    .home1-p
    {
        padding:0px;
        font-size: 12px;
    }
    .home1-right img 
    {
        padding-top: 0px;
    }
    .home1-banner
    {
        height: 600px;
        object-fit: cover;
    }
   
}
.container {
    position: relative;
    width: 100%;
    max-width: 220px;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 20px;
    margin-left: 0px;
    margin-bottom: 5px;
    
    box-shadow: 2px 2px 2px 0px rgba(0.2, 0.2, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.6s;

}
@media (min-width:1200px) {
  

.container:hover
{
  transform: translateY(-10px);
  transform: translateZ(10);
  transition-duration: 0.6s;
  box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
}

  
  
  .image {
    display: flex;
  width: 220px;
height: 296px;
object-fit: cover;

  }
  
  .overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(49, 49, 48);
   
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0.6;
    color: white;
    
   
    text-align: center;

 
  }
  .overlay h5
  {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 3px;
  }
  
  .overlay span{
      font-size: 10px;
  }

  .container:hover .overlay {
    opacity: 1;
  }
.home2-body
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
}
.home2-top
{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 10px;
}
.home2-bottom
{
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-left: 10px;
}
.home2-body h3
{

    margin-bottom: 20px;
    border-bottom: 1px solid rgb(218, 217, 217);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #03363d;

}
.card-body {
    background-color: white;
    border-radius: 10px;
    width: 240px;
    height: 410px;
    margin: 7px;
    position: relative;

 
    
    padding: 0;

    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
  }
  
  @media (min-width:1200px)
  {
  .card-body:hover
  {
    transform: translateY(-6px);
    transform: translateZ(20);
    transition-duration: 0.7s;
    box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
  .card-body img {
  
    width: 270px;
    height: 253px;
    object-fit: contain;
border-radius: 15px;
   
  }
  
  .card1-top {
    background-color: transparent;
  
    width: 100%;
    overflow: hidden;
    text-align: center;
  
    border-radius: 10px;
  
  }
  
  .card-bottom {

    min-height: 45%;
  
    min-width: 98%;
    max-width: 98%;
    margin-top: 0px;
    overflow-wrap: break-word;
    text-align: center;
    height: 40px;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  }
  .product-name 
  {
      color: rgb(110, 109, 109);
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 1000;
      font-family: "Poppins" , sans-serif;
      padding-left: 4px;
  }
  .top-heading
  {
      font-size: 9px;
      font-family: "Poppins" , sans-serif;

  }
.price-cut
{
    font-size: 11px;
    
    color: grey;
}

.add-to-cart-btn
{
    background-color: rgb(60, 133, 175) !important;
    transform: scale(0.9);
    font-family: "Poppins" , sans-serif !important;

    
  
    
}
.price-card
{
  color: red;
}
.see-details-btn
{
  margin-top: 3px;
  font-size: 10px;
  transform: scale(0.8);
  cursor: pointer;
  

}
.rating-star
{
  font-size: 11px;
  color: orange;
}
@media(max-width:600px) 
{
  .card-body{
    width:200px;
    height: 330px;
  }
  
  .card-body img
  {
   height: 170px;
  }
  .card-bottom{
    margin-top: 0px;
  }
  .add-to-cart-btn
  {
    transform: scale(0.75);
  }
  
}

.offer-text 
{
  position: absolute;
  top: 0px;

  background-color: rgba(224, 34, 34, 0.801);
  border-radius: 0%;
 padding: 10px;
 color: white;
 font-weight: 600;
 font-family: "Poppins" , sans-serif;

}
.offer-text span
{
  font-family: "Poppins" , sans-serif;
}
.card-collection
{
 display: flex;
 flex-wrap: wrap;
width: 100%;
 justify-content: space-around;
 transition-duration: 5s;
text-align: center;
margin-bottom: 3%;
margin-top: 3%;
}
.card-collection-heading
{
 

  width: 100%;



}
.card-collection-h2
{

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 27px;
  color: #03363d;

}

.card-collection-hr
{
  max-width: 100%;
  margin-top: -10px;


}


@media (max-width:500px)
{
  .card-collection-h2
  {
    font-size: 24px;
    font-weight: 600;
  }
}
@media(min-width:1300px){
  .card-collection
  {
    padding-left: 5%;
    padding-right: 5%;
  }
  .card-collection-h2
  {
    font-size: 30px;
    font-weight: 600;
  }
  
}

.cards{
display: flex;
overflow-y: hidden;
overflow-x: scroll;
}
.online-offline {
  padding-left: 40px;
  padding-right: 40px;
  
}
.styles_row-content__QOGZd {
  height: 100px;
}
.styles_faq-row-wrapper__3vA1D h2 {
  margin: 0 auto;
  color: #03363d !important;
font-weight: 800 !important;
  font-size: 25px !important;
  font-family:  sans-serif !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 20px 0px !important;

  display: flex;
  flex-wrap: wrap;
  color: black;
  font-size: 14px !important;
  cursor: pointer;
  align-items: center;
  font-weight: 500;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: #969696 !important;
  font-size: 12px;
  
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  padding-right: 0px;
}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4px 20px !important;
  border-bottom: 1px solid #ccc !important;
}

.styles_faq-row-wrapper__3vA1D {
  background-color: transparent !important;
  font-family: "Fira Sans", sans-serif;
}
@media (max-width:600px) {
  .styles_faq-row-wrapper__3vA1D h2
  {
    font-size: 21px !important;
  }
  
}
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {

text-align: left;
}

#title-Tag-Line {
    font-size: 20px;
  }
  /* .card-item__bg{
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: block;
    position: relative;
    margin: 30px auto;
    transform: translate(0px, 50px);
    z-index: 5;
  } */
  
  /* form animation starts */
  .form {
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 5px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 0;
    right: 0;
    color: white;
    
    border-top: 5px solid #f59a9a;
  /*   z-index: 1; */
    animation: bounce 1.5s infinite;
    background-color: #537895;
background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);
  }
  ::-webkit-input-placeholder {
    font-size: 1.3em;
  }

  
  .title{
    display: block;
    font-family: sans-serif;
    margin: 10px auto 5px;
    width: 300px;
  }
  .termsConditions{
    margin: 0 auto 5px 80px;
  }
  
  .pageTitle{
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
  .secondaryTitle{
    color: rgb(207, 207, 207);
    text-align: center;
  }
  
  .name {
    background-color: #ebebeb;
    color: black;
  }
  .name:hover {
    border-bottom: 5px solid #0e3721;
    height: 30px;
    width: 380px;
    transition: ease 0.5s;
  }
  
  .email {
    background-color: #ebebeb;
    height: 2em;
  }
  
  .email:hover {
    border-bottom: 5px solid rgb(143, 221, 143);
    height: 30px;
    width: 380px;
    transition: ease 0.5s;
  }
  
  .message {
    background-color: #ebebeb;
    overflow: hidden;
    height: 10rem;
  }
  
  .message:hover {
    border-bottom: 5px solid #0e3721;
    height: 12em;
    width: 380px;
    transition: ease 0.5s;
  }
  
  .formEntry {
    display: block;
    margin: 30px auto;
    min-width: 274px;
    padding: 10px;
    border-radius: 2px;
    border: none;
    transition: all 0.5s ease 0s;
  }
  
  .submit {
    width: 200px;
    color: white;
    background-color:rgb(180,211,80);
    font-size: 20px;
  }
  
  .submit:hover {
    box-shadow: 15px 15px 15px 5px rgba(78, 72, 77, 0.219);
    transform: translateY(-3px);
    width: 300px;
    border-top: 5px solid #0e3750;
    border-radius: 0%;
  }
  
  @keyframes bounce {
    0% {
      tranform: translate(0, 4px);
    }
    50% {
      transform: translate(0, 8px);
    }
  } 
  
  
.review__main {
  margin: 0px;
  padding: 0px;

  box-sizing: border-box;
  background-color:  rgb(31, 37, 56);
  padding-bottom: 30px;
 
}
a {
  text-decoration: none;
}
#testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  
}
.testimonial-heading {
  letter-spacing: 1px;
  margin: 0px 0px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 800;
}
.testimonial-heading h1 {
  font-size: 2.2rem;
  font-weight: 500;
  background-color: rgb(31, 37, 56);
  color: #ffffff;
  padding: 11px 15px;
  margin-top: 24px;
  padding-top: 16px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 800;
}
.testimonial-heading span {
  font-size: 1.3rem;
  color: white;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.testimonial-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  width: 100%;
  
  
}
.testimonial-box {
  width: 500px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  margin: 15px;
  cursor: pointer;
  height: 240px;
  border-radius: 10px;
  background-color: #fd5f6d;

  
}
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.profile {
  display: flex;
  align-items: center;
}
.name-user {
  display: flex;
  flex-direction: column;
}
.name-user strong {
  color:white;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  font-weight: 800;
  font-family: 'Poppins' , sans-serif;
}
.name-user span {
  color:white;
  font-size: 0.8rem;
}
.reviews {
  color: white;
  font-size: 17px;
}
.box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.client-comment p {
  font-size: 0.9rem;
  color: white;
  font-weight: 500;
  font-family: 'Poppins' , sans-serif;
}
.testimonial-box:hover {
  transform: translateY(-10px);
  transition: all ease 0.3s;
}

@media (max-width: 1060px) {
  .testimonial-box {
    width: 45%;
    padding: 10px;
  }
}
@media (max-width: 790px) {
  .testimonial-box {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .testimonial-heading h1 {
    font-size: 21px;
    padding: 0;
  }
}
@media (max-width: 340px) {
  .box-top {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .reviews {
    margin-top: 10px;
  }
}
::selection {
  color: #ffffff;
  background-color: #252525;
}

.checkout-item {
  max-width: 600px;
  display: flex;
  flex-direction: row;
  background-color: white;
  margin: 10px;
}

.checkout-heading {
  font-family: "Poppins", sans-serif;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 2%;
  font-size: 20px;
  color: rgb(18, 41, 33);
  font-weight: 600;
  font-size: 25px;
  border-bottom: 1px solid grey;
}
.checkout-description h6 {
  font-family: "Poppins", sans-serif;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 2%;
  font-size: 20px;
  color: rgb(67, 68, 68);
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.checkout-image {
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkout-image img {
  width: 100%;
}

.checkout-description {
  width: 100%;
  margin-left: 20px;
}

.checkout-mid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkout-mid span {
  font-family: sans-serif;
  margin-right: 16px;
  cursor: pointer;
  color: grey;
  font-weight: 600;
}

.checkout-mid span:hover {
  color: black;
}

.checkout-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkout-quantity span {
  margin: 0 10px;
  padding: 0;
}

.qt-icon {
  font-size: 20px;
  cursor: pointer;
}

.qt-icon:hover {
  transform: scale(1.2);
}

.checkout-price {
  display: flex;
  flex-direction: column;
}

.checkout-price span {
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.checkout-price .stock {
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: green;
}

@media (max-width: 600px) {
  .checkout-item {
    margin: 3px;
  }

  .checkout-heading {
    font-size: 20px;
  }
  .checkout-description h6 {
    font-size: 16px;
  }

  .checkout-price span {
    font-size: 13px;
  }

  .checkout-mid span {
    font-size: 13px;
  }

  .checkout-quantity span {
    margin: 0 3px;
  }
}

.aboutus__main{
    display: flex;
   

    font-family: "Mr Eaves XL Modern", sans-serif;
    justify-content:center;
  font-family: 'Poppins' , sans-serif;

}

.aboutus__left{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border:  1px solid #F4F4F4;


}

.aboutus__right{
    max-width: 32%;
}
.aboutus_left_first{
    display: flex;
    flex-direction: column;
}

.aboutus_left_first h4{
 
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
}
.aboutus_left_first p{
   
    font-size: 12px;
    color: #595857;
    text-align: justify;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
}

.aboutus_left_second{
    display: flex;
    justify-content: space-around;
   
    
}


.our_product_left h4{
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
    font-weight: bold;

}
.our_product_left img{
    min-width: 100px;
    max-width: 244px;

}
.our_product_right h4{
    font-size: 15px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    padding: 8px 0 4px 0;
    font-family: 'Poppins' , sans-serif;


}
.our_product_right p{
  
    font-size: 12px;
    color: #595857;
    text-align: justify;
    font-weight: normal;
    line-height: 1.5;
    padding: 8px 0 4px 0;
    font-family: 'Poppins' , sans-serif;



}
.our_product_right ul{
    margin-left: 0px;
}
.our_product_right ul li{
    
    font-size: 12px;
    color: #595857;
    
    font-weight: normal;
    line-height: 1;
    padding: 8px 0 4px 0;


}




@media screen and (max-width:600px){
    .aboutus__main{
       
        flex-direction:column;
        padding: 28px;
        

    }
    .aboutus__left{
        border: none;
        
    }
    
    .aboutus__right{
       
        overflow: hidden;
        max-width: 100%;
        
    }
    .aboutus__right_ul{
        visibility: hidden;
    }
    
    .aboutus_left_second{
        display: flex;
        flex-direction: column;
    }
    .aboutusimage img{
        width: 100%;
       
    }

}
@media screen and (min-width: 603px){
    .aboutus__main{
       
       
        padding: 28px;
        margin-top: 10px;
        margin-bottom: 10px;
        

    }
    .aboutus__left{
       padding: 0 64px;
       flex: 0.80 1;
    }
    
    .aboutus__right{
        color: red;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
     
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .aboutus__right ul li{
       
        margin-right: 23px;
        font-size: 14px;
        padding-top: 5px;
        
    } 
    
    .aboutus_left_second{
        display: flex;
        flex-direction: row;
    }
    .aboutus_left_first h4{
        margin: 10px;

        
    }
    .our_product_right{
        margin-top: 10px;
        margin-left: 50px;
    }
    .aboutus_left_first p{
       margin: 10px;
        
    }
    .our_product_right h4{
        margin: 10px;
    
    
    }
    .our_product_right ul{
      
        margin: 18px;
     
     
     }
    .our_product_right p{
      
       margin:10px;
    
    
    }
    

}


@media screen and (min-width:600px) {


    .aboutus__top{
        display: flex;
        flex-direction: column;
        
        margin: 80px;
    
    }
    
    .aboutustop__first img{
    
        width: 100%;
        height: 400px;
        
        object-fit: fill;
    
    }
    .aboutustop__second{
        display: flex;
        justify-content: center;
         font-family: "Lato", sans-serif;
         background-color: rgb(244,244,244);
        
    }
    
    .top-first{
    
        
        text-align: center;
        background-color: rgb(255,255,255);
        z-index: 1;
        margin-top: -23px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
    }
    
    .top-first img{
        width: 80px;
        height: 80px;
        margin: 6px auto;
    }
    
    .top-second{
    
        margin-left: 30px;
        text-align: center;
        background-color:rgb(255,255,255);
        z-index: 1;
        margin-top: -23px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
        
    }
    .top-second  h1{
        margin-bottom: .5rem;
        font-weight: 600;
         line-height: 2.2;
         font-size: 17px !important;
          color: black;
          font-family: 'Poppins' , sans-serif;


    }
    .top-first  h1{
        margin-bottom: .5rem;
        font-weight: 600;
         line-height: 2.2;
         font-size: 17px !important;
          color: black;
          font-family: 'Poppins' , sans-serif;
          


    }
    .top-second  p{
        font-size: 12px;
        color: #595857;
        padding: 6%;
        font-weight: normal;
        line-height: 1.5;
        font-family: 'Poppins' , sans-serif;
        

    }
    .top-first  p{
        font-size: 12px;
        color: #595857;
        padding: 6%;
        font-weight: normal;
        line-height: 1.5;
        font-family: 'Poppins' , sans-serif;
        

    }
    
    .top-second img{
        width: 80px;
        height: 80px;
        margin: 1px auto;
    }
    .contact-btn{
        
        display: block;
        margin: 50px auto;
        padding: 4px;
         color: #fff;
         background-color:#d26e4b;
         font-weight: 600;
         border: 1px solid white;
    }

    .submit{
        margin-left: 102px !important;
    }
    
}

@media screen and (min-width:1010px){
    .aboutus__top{
        margin: 10px 200px;
    }
}

@media  screen and  (max-width:600px){

    .aboutus__top{
        display: flex;
        flex-direction: column;
        margin: 10px;
    
    }
    
    .aboutustop__first img{
    
        width: 100%;
        height: 205px;
        
        object-fit: cover;
    
    }
    .aboutustop__second{
        display: flex;
        flex-direction: column;
        justify-content: center;
         font-family: "Lato", sans-serif;
         background-color: rgb(244,244,244);
        
    }
    
    .top-first{
    
        
        text-align: center;
        background-color: rgb(255,255,255);
        border: 1px solid #8080802e;
        
        
        
    }
    
    .top-first img{
        width: 80px;
        height: 80px;
        margin: 6px auto;
    }
    
    .top-second{
    
        
        text-align: center;
        background-color:rgb(255,255,255);
       border: 1px solid #8080802e;
        
        
    }
    .top-second  h1{
        margin-bottom: .5rem;
        font-weight: 600;
         line-height: 2.2;
         font-size: 17px !important;
          color: black;
          font-family: 'Poppins' , sans-serif;

    }
    .top-first  h1{
        margin-bottom: .5rem;
        font-weight: 600;
         line-height: 2.2;
         font-size: 17px !important;
          color: black;
          font-family: 'Poppins' , sans-serif;


    }
    .top-second  p{
        font-size: 12px;
        color: #595857;
        padding: 6%;
        font-weight: normal;
        line-height: 1.5;
        font-family: 'Poppins' , sans-serif;
        

    }
    .top-first  p{
        font-size: 12px;
        color: #595857;
        padding: 6%;
        font-weight: normal;
        line-height: 1.5;
        font-family: 'Poppins' , sans-serif;
        

    }
    
    .top-second img{
        width: 80px;
        height: 80px;
        margin: 1px auto;
    }
    .contact-btn{
        
        display: block;
        margin: 50px auto;
        padding: 4px;
         color: #fff;
         background-color:#d26e4b;
         font-weight: 600;
         border: 1px solid white;
    }
    .submit{
        margin-left: 85px !important;
    }
    
}

.__contactus_a {
  text-decoration: none;
  color: white;
}
.contact-btn a:hover {
    text-decoration: none;
    color: white;
  }



.privacy__main{
  margin: 20px auto;
  
  width: 100%;
  padding: 32px;
  
 
  
}
.privacy__main h1{
    font-size: 20px;
    color: #ED7745;
  
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
    
}
.privacy__main h3{
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
    
}
.privacy__main h5{
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
}

.privacy__p{

    font-size: 12px;
    color: #595857;
    text-align: justify;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;

}

.privacy__second ol li{
   
    font-size: 12px;
    color: #595857;
    text-align: justify;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;



}
.privacy__second ol{
    padding-left: 20px;
}

@media screen and ( min-width:600px) {
    .privacy__main{
      
        padding: 0 130px;
        width: 78%;
        margin-top:70px;
        

      }
      .privacy__second ol{
          padding-left: 20px;
      }
      .privacy__second ol li{
    
        font-size: 12px;
        color: #595857;
        text-align: justify;
        padding: 8px 0 4px 0;
        font-weight: normal;
        line-height: 1.5;
      
    
    
    }
    
}
.online-offline {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.styles_row-content__QOGZd {
  height: 100px;
}
.styles_faq-row-wrapper__3vA1D h2 {
  margin: auto;
  color: #f9821c;
width: 100%;
  font-size: 29px;
  font-family: 'Poppins' , sans-serif !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 10px 0px;
  
  display: flex;
  flex-wrap: wrap;
  color: black;
  font-size: 13px;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  font-family: 'Poppins' , sans-serif !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: #969696;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 12px;
  padding: 4px;
}
styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  padding-right: 0em;
}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1px 13px;
  border-bottom: 1px solid #ccc;
}

.styles_faq-row-wrapper__3vA1D {
  background-color: #f4f5f7;
  font-family: "Fira Sans", sans-serif;
}

@media (max-width:600px) {
  .online-offline
  {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }
  
}
.payment__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  padding-left: 50px;
  padding-right: 50px;
  
}
.payment__p_h {
 
  font-size: 14px;
  color: #595857;
  text-align: justify;
  padding: 8px 0 4px 0;
  padding-left: 20px;
  font-weight: 300;
  max-width: 85%;
  line-height: normal;
  color: #ed7745;
}
.payment__h {
  text-align: center;
 margin-bottom: 25px;
  margin-top: 40px;
  font-size: 24px;
  color: #ed7745;
 padding: 8px 0 4px 0;
  font-weight: 600;
  line-height: 1.5;
}

.payment-policy h1 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 23px;
  margin-bottom: 25px;
  color: #ed7745;
  font-family: 'Poppins' , sans-serif;
}
.payment-policy h2{
  font-weight: 600;
    line-height: 1.5;
    font-size: 18px;
  margin-bottom: 25px;
  color: #ed7745;
  font-family: 'Poppins' , sans-serif;
}

.payment__first {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 42px 40px;
}
.payment__second {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1px;

  margin: 42px 40px;
}
.payment__third {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment__fourth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment__fifth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment__six {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment-img {
  width: 95%;
  padding-top: 26px;
}
.payment__p {
  
  font-size: 12px;
  color: #595857;
  text-align: justify;
  padding: 1px 0 4px 0;
  padding-left: 20px;
  font-weight: 300;
  max-width: 85%;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  
}

.p_f_f {
  flex: 0.5 1;
}
.p_f_s {
  flex: 0.5 1;
}

@media screen and (min-width: 1100px) {
  .payment__main {
    padding-left: 140px;
    padding-right: 140px;
  }
}
@media screen and (max-width: 600px) {
  .payment__main {
    padding: 0px;
  }

  .payment__first {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 42px 40px;
  }
  .payment__second {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__third {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__fourth {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__fifth {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__six {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
}

.product-page {
 
  margin-bottom: 50px;

}
.product-page Button {
  margin-left: 6px;
}
body {
  line-height: 1.5;
}
.card-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 0;
}
.card {
  box-shadow: 2px 2px 2px 0px rgba(0.2, 0.2, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 1s;
}

img {
  width: 100%;
  display: block;
  object-fit: contain;
}
.img1 {
  height: 70px;
  width: 100%;
  margin-left: 0px;
  object-fit: contain;
}
.img-display {
  overflow: hidden;
}
.img-showcase {
  display: flex;
  width: 100%;
  transition: all 1s ease;
  height: 400px;
}
.img-showcase img {
  min-width: 100%;
}
.img-select {
  display: flex;
}

.img-item {
  margin: 0.3rem;
  width: 90px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 30px;
  border: 3px solid rgb(216, 216, 216);
  border-radius: 10px;
}
.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
  margin-right: 0;
}
.img-item:hover {
  opacity: 0.8;
}
.product-content {
  padding: 2rem 1rem;
  
}
.product-content h5 {
  font-weight: 600;
  margin-left: 10px;
  font-family: "Poppins" , sans-serif;
  color: green;
  font-size: 14px;
}
.product-title {
  font-size: 1.7rem;
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  color: #141f2b;
  margin: 1rem 0;
  font-family: "Poppins" , sans-serif;
  text-align: center;
}
.product-title::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: #12263a;
  text-align: center;
}
.product-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  background: #256eff;
  color: #fff;
  padding: 0 0.3rem;
  transition: all 0.5s ease;
}
.product-link:hover {
  opacity: 0.9;
}
.product-rating {
  color: #ffc107;
  margin-left: 8px;
}
.product-rating span {
  font-weight: 600;
  color: #252525;
  
}
.product-price {
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
}
.product-price span {
  font-weight: 600;
  
}
.last-price span {
  color: grey;
  text-decoration: line-through;
}
.last-price {
  margin-bottom: 0px;
}
.new-price span {
  color: red;
  font-weight: 600;
  font-size: 16px;
  font-family: sans-serif;
}
.product-detail h2 {
  text-transform: capitalize;
  color: #286199;
  padding-bottom: 0.2rem;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
  font-family: "Poppins" , sans-serif;
}
.product-detail p {
  font-size: 0.9rem;
  padding: 0.3rem;
  opacity: 1;
 
}
.product-detail ul {
  margin: 1rem 0;
  font-size: 0.9rem;
  text-align: left;
  margin-left: 10px;
  
}
.product-detail ul li {
  margin: 0;
  background-size: 18px;
  padding-left: 0;
  margin: 0.4rem 0;
  font-weight: 600;
  opacity: 0.9;

}
.product-detail ul li span {
  font-weight: 400;
  font-family: "Poppins" , sans-serif;
}
.purchase-info {
  margin: 1.5rem 0;
}
.purchase-info input,
.purchase-info .btn {
  border: 1.5px solid #ddd;
  border-radius: 0px;
  text-align: center;
  padding: 0.45rem 0.8rem;
  outline: 0;
  margin-right: 0rem;
  margin-bottom: 0;
  width: 200px;
  font-weight: 1000;
  font-family: "Poppins" , sans-serif;
}
.purchase-info .btn:hover {
  transform: scale(1.1);
  transition: all 0.5s ease;
}
.purchase-info input {
  width: 60px;
}
.purchase-info .btn {
  cursor: pointer;
  color: #fff;
}
.purchase-info .btn:first-of-type {
  background: #8aaef7;
}
.purchase-info .btn:last-of-type {
  background: #2b92bb;
}
.purchase-info .btn:hover {
  opacity: 0.9;
}
.social-links {
  display: flex;
  align-items: center;
}
.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #000;
  border: 1px solid #000;
  margin: 0 0.2rem;
  border-radius: 50%;

  font-size: 0.8rem;
  transition: all 0.5s ease;
}
.social-links a:hover {
  background: #000;
  border-color: transparent;
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  .card-wrapper {
    /* height:100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-imgs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .product-content {
    padding-top: 0;
  }
}

.quantity {
  font-size: 25px;

  padding: 2px;
  border-radius: 50%;
  width: 40px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.bottom-menu {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 20px;
}
.product-accordian {
  display: none;
}

@media (max-width: 600px) {
  .img-showcase {
    height: 300px;
  }
  .product-title {
    font-size: 21px;
  }
  .bottom-menu {
    display: none;
  }
  .product-accordian {
    display: block;
  }
}

.relatedh2 {
  text-align: left;
  padding-left: 40px;
  padding-top: 20px;
  font-size: 25px;
  font-family: sans-serif;
  text-align: center;
  font-weight: 600;
}
.qualityPromise {
  list-style: disc !important;
  text-align: left !important;
}
.termsheading {
  text-align: left !important;
  font-size: 15px;
}

.terms ul li {
  font-size: 14px;
  text-align: left !important;
}
.terms ul {
  margin-left: 30px;
  text-align: left !important;
}
.accordianitems li {
  font-size: 12px;
  list-style: disc !important;
  text-align: left !important;
}
.accordianitems {
  margin-left: 10px;
  text-align: left !important;
}

.accodidanHeading {
  text-align: left !important;
  font-size: 13px;
}
.related_products{
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 3%;
  margin-bottom: 2%;
}
.single-seater
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}  
.single-seater h2
{
    border-bottom: 1px solid rgb(172, 168, 168);
    margin-left: 20;
    font-family: "Poppins" , sans-serif;
}
.top-cat-page
{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 10px;
}

.bottom-cat-page img
{
    width: 252px;
    height: 300px;
    margin: 10px;
    object-fit: contain;
    justify-self: flex-start;
}
.top-cat-page p
{
    width: 100%;
 

text-align: center;
   
    justify-content: center;
  flex-wrap: wrap;
    padding-left: 10px;

}
.bottom-cat-page
{ 
    width: 100% ;
    margin-top: 0px;
    margin-left: 25px;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-left: 10px;
}

@media (max-width:600px)
{
    .top-cat-page
    {
        padding-left: 0px;
    }
    .top-cat-page p
    {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        
    }
    .single-seater h2 
    {
        font-size: 20px;
        text-align: center;
        font-weight: 600;
        border-bottom: 1px solid rgb(179, 179, 179);
        font-family: "Poppins" , sans-serif;
    }
    .top-cat-page p 
    {
        font-size: 13px ;
        
    }


}

.single-seater-1 span
{
    font-size: 12px;
    font-family: "Poppins" , sans-serif;
}
.single-seater
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}  
.single-seater h2
{
    border-bottom: 1px solid rgb(172, 168, 168);
    margin-left: 20;
    font-family: "Poppins" , sans-serif;
    color: rgb(35, 48, 49);
    font-weight: 600;
}
.top-cat-page
{
    margin-top: 20px;
}

.bottom-cat-page img
{
    width: 252px;
    height: 300px;
    margin: 6px;
    object-fit: contain;
    justify-self: flex-start;
}
.top-cat-page p
{
    width: 50%;
justify-self: center;
padding-left: 60px;
text-align: left;
font-family: "Poppins" , sans-serif;
font-weight: 300;
}
.bottom-cat-page
{ 
    width: 100% ;
    margin-top: 0px;
    margin-left: 0px;
}

@media (max-width:600px)
{
    .top-cat-page p
    {
        width: 100%;
        padding-left: 5px;
        font-family: "Poppins" , sans-serif;
font-weight: 300;
       
       
    }
    .single-seater h2 
    {
        font-size: 24px;
        text-align: center;
        font-weight: 600;
        border-bottom: 1px solid rgb(179, 179, 179);
        font-family: "Poppins" , sans-serif;
        font-weight: 600;
    }
    .top-cat-page p 
    {
        font-size: 12px ;
        
    }


}

.living-room-1
{
   width: 80%;
   display: flex;
   padding-left: 100px;
   padding-right: 100px;
   justify-content: center;
   align-self: center;
   flex-wrap: wrap;

  
}

.new-card-wrapper
{
    border: 2px solid rgb(235, 231, 231);
    width: 250px;
    height: 360px;
    margin-left: 10px;
    margin: 5px;
    background-color: white;
    box-shadow: 2px 2px 2px 0px rgba(0.2, 0.2, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition-duration: 0.6s;

}
@media (min-width:1200px)
{
.new-card-wrapper:hover{
    transform: translateY(-5px);
    box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
}
.new-card-wrapper img{
    object-fit: contain;
    width: 233px;
    height: 200px;
    

  
}
.new-card-wrapper span , del
{
    font-size: 10px;
    font-family: "Poppins" , sans-serif;
}
.new-card-wrapper h5
{
    font-size: 13px;
    font-weight: 600;
   margin-left: 2px;
   margin-right: 2px;
   font-family: "Poppins" , sans-serif;
}
.offer-text-cat 
{  background-color: rgba(224, 34, 34, 1);
    width: 50px;
    color: white;
    position: relative;
    top: 0;
    z-index: 2;
    margin-bottom: -30px;
    height: 30px;
   
    font-family: sans-serif;

}
.offer-text-cat span
{
    font-family:  sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-family: "Poppins" , sans-serif;
}


@media (max-width:600px)
{
    .bottom-cat-page
    {
    
       margin-left: 0px;
       margin-right: 0;

    }
    .living-room-1
    {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
}
.add-to-btn
{
    font-size:  14px !important;
    background-color: #3c6a9e !important;
}
.feedback-wrapper
{
    padding-left: 25%;
    padding-right: 25%;
    margin-bottom: 5%;
    margin-top: 5%;

}
.feedback-wrapper h2{
    margin-bottom: 3%;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    
}

@media (max-width: 600px) {
    .feedback-wrapper
    {
        padding-left: 5%;
        padding-right: 5%;

    }
    
    
}
.testimonial-body
{
display: flex;
flex-direction: column;
text-align: center;
align-items: center;
margin-bottom: 40px;
margin-top: 0px;
   
}
.testimonial-heading
{
    padding: 0px;
    width: 100%;
}
.testimonial-body img
{
  
    padding-left: 0%;   
    padding-right: 0%;
    margin-top: 0;

}
.testimonial-body h4
{
width: 100%;
   border-bottom: 1px solid black;
   margin-bottom: 3%;
   font-family: 'Poppins' , sans-serif;
   font-weight: bold;

}
.client-card
{
    height: 350px;
    width: 300px;
  

    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 2px 0px rgba(0.2, 0.2, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 1s;
    margin-right: 20px;
    margin-left: 10px;
    margin-top: 10px;



}
.client-card:hover
{
    transform: translateY(-10px);
    transition-duration: 1s;
    box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.client-card img
{
    object-fit: cover;
    height: 150px;
    position: relative;
    z-index: -1;
    
}
.client-card-top
{ margin-top: 10px;
    border-radius: 50%;
    height: 150px;
    border: 2px solid rgb(138, 133, 133);
    width: 150px;
    overflow: hidden;
   
}
.client-card-bottom p
{
   font-size: 13px;
   padding: 3%;
   font-family: 'Poppins' , sans-serif;
}
.our-clients
{
    display: flex;
    margin-bottom: 4%;
    flex-wrap: wrap;
    width: 100%;
     align-items: center;
     justify-content: center;
    
}
.testimonial-carousel
{
   width: 100%;
   
   
}
.testimonial-items
{
    height: 240px;
    width: 320px;
    border: 0px solid rgb(161, 159, 159);
   
  
}
.test-rating
{
    padding-top: 10px;
}
.testimonial-items p{
    padding: 10px;
    font-size: 13px;
    font-family: 'Poppins' , sans-serif;
}


@media (max-width:600px)
{
    .client-card
    {
        margin-right: 0px;
        margin-left: 0px;
    
    }
    .our-clients
    {
        margin-bottom: 40px;
    }
}




.shipping-policy
{
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 20px;
}
.shipping-policy h3
{
    text-align: center;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
    color: #E57200;
    font-weight: bold;
}
.shipping-policy ul ,p
{
    list-style: disc;
    margin-left: 10px;
    font-size: 13px;

}
.shipping-policy ol
{
    margin-left: 10px;
    font-size: 13px;
}
.shipping-h6
{
    color: rgb(199, 135, 17);
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 10px;
}
.shipping-policy span
{
    font-size: 13px;
  
}

.shipping-policy li{
    margin-bottom: 10px;
}
.shipping-policy h7
{
    font-weight: 700;
    font-size: 14px;
}

@media (max-width : 600px) {
    .shipping-policy
    {
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .shipping-policy h3
    {
        font-size: 20px;
        font-weight: 1000;
        text-align: center;
    }
}
.home3-body 
{
    margin-top: 0px;
    margin-bottom:0px;
  object-fit: contain;
width: 100%;
height : -webkit-fit-content;
height : -moz-fit-content;
height : fit-content;
display: flex;
align-items: center;
justify-content: center;

}
.home3-body img
{
    object-fit: contain;
    width: 100%;
}

.landing-container
{
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  
flex-wrap: wrap;
margin-bottom: 50px;
}
.landing-testimonial-txt 
{

  border-bottom: 2px solid black;
  font-weight: 800;
  text-align: center;
  font-family: 'Poppins' sans-serif;
}

.lading-image  img
{
    object-fit: contain;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
 
   
}
.landing-img
{
    height: 650px;
}
.landing-image-text h1,h6,p
{
  font-weight: 800;
  font-style: bold;
  text-align: center;
  margin-top: 10px;
}
.landing-image-text ul
{
  text-align: left;
}

.utube-video 
{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.utube-video iframe{
  width: 600px;
  height: 400px;
}

.shop-now-btn
{
  border: 1px solid rgba(4, 152, 189, 0.5);
  background-color: rgb(0, 162, 255);
  border-radius: 10px;
  padding: 10px;
  color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
  align-self: center;
  margin: 10px;
  margin-bottom: 30px;
}
.shop-now-btn:hover{
  transform: scale(1.2);
  animation: ease-in-out;
  animation-delay: 1s;
}

@media (max-width:700px) {

  .utube-video iframe{
    width: 370px;
    height: 300px;
  }
  .landing-img 
  {
    height: 250px;
  }
  

}
.points{
    text-align: left;
}
