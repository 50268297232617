@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


.card-collection
{
 display: flex;
 flex-wrap: wrap;
width: 100%;
 justify-content: space-around;
 transition-duration: 5s;
text-align: center;
margin-bottom: 3%;
margin-top: 3%;
}
.card-collection-heading
{
 

  width: 100%;



}
.card-collection-h2
{

  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 27px;
  color: #03363d;

}

.card-collection-hr
{
  max-width: 100%;
  margin-top: -10px;


}


@media (max-width:500px)
{
  .card-collection-h2
  {
    font-size: 24px;
    font-weight: 600;
  }
}
@media(min-width:1300px){
  .card-collection
  {
    padding-left: 5%;
    padding-right: 5%;
  }
  .card-collection-h2
  {
    font-size: 30px;
    font-weight: 600;
  }
  
}

.cards{
display: flex;
overflow-y: hidden;
overflow-x: scroll;
}