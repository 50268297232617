@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Play&family=Quicksand&display=swap");

.online-offline {
  padding-left: 40px;
  padding-right: 40px;
  
}
.styles_row-content__QOGZd {
  height: 100px;
}
.styles_faq-row-wrapper__3vA1D h2 {
  margin: 0 auto;
  color: #03363d !important;
font-weight: 800 !important;
  font-size: 25px !important;
  font-family:  sans-serif !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 20px 0px !important;

  display: flex;
  flex-wrap: wrap;
  color: black;
  font-size: 14px !important;
  cursor: pointer;
  align-items: center;
  font-weight: 500;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: #969696 !important;
  font-size: 12px;
  
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  padding-right: 0px;
}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4px 20px !important;
  border-bottom: 1px solid #ccc !important;
}

.styles_faq-row-wrapper__3vA1D {
  background-color: transparent !important;
  font-family: "Fira Sans", sans-serif;
}
@media (max-width:600px) {
  .styles_faq-row-wrapper__3vA1D h2
  {
    font-size: 21px !important;
  }
  
}
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {

text-align: left;
}
