.home3-body 
{
    margin-top: 0px;
    margin-bottom:0px;
  object-fit: contain;
width: 100%;
height : fit-content;
display: flex;
align-items: center;
justify-content: center;

}
.home3-body img
{
    object-fit: contain;
    width: 100%;
}
