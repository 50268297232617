.navbar4-body
{
   width: 100%;
  background-color:  rgb(31, 37, 56);

  color: rgb(224, 193, 20);
 
}
.navbar4-body h5{
    font-size: 12px;
    text-align: center;
    font-weight: 800;
    font-family: 'Farro', sans-serif;
    padding: 3px;
    margin-bottom: 0;
    
}
@media (min-width: 1000px)
{
   .navbar4-body
   {
      position: sticky !important;
      top: 60px;
      z-index: 3;

   }
}