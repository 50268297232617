.feedback-wrapper
{
    padding-left: 25%;
    padding-right: 25%;
    margin-bottom: 5%;
    margin-top: 5%;

}
.feedback-wrapper h2{
    margin-bottom: 3%;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    
}

@media (max-width: 600px) {
    .feedback-wrapper
    {
        padding-left: 5%;
        padding-right: 5%;

    }
    
    
}