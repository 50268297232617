@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Farro:wght@700&family=Itim&display=swap");
.product-page {
 
  margin-bottom: 50px;

}
.product-page Button {
  margin-left: 6px;
}
body {
  line-height: 1.5;
}
.card-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 0;
}
.card {
  box-shadow: 2px 2px 2px 0px rgba(0.2, 0.2, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 1s;
}

img {
  width: 100%;
  display: block;
  object-fit: contain;
}
.img1 {
  height: 70px;
  width: 100%;
  margin-left: 0px;
  object-fit: contain;
}
.img-display {
  overflow: hidden;
}
.img-showcase {
  display: flex;
  width: 100%;
  transition: all 1s ease;
  height: 400px;
}
.img-showcase img {
  min-width: 100%;
}
.img-select {
  display: flex;
}

.img-item {
  margin: 0.3rem;
  width: 90px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 30px;
  border: 3px solid rgb(216, 216, 216);
  border-radius: 10px;
}
.img-item:nth-child(1),
.img-item:nth-child(2),
.img-item:nth-child(3) {
  margin-right: 0;
}
.img-item:hover {
  opacity: 0.8;
}
.product-content {
  padding: 2rem 1rem;
  
}
.product-content h5 {
  font-weight: 600;
  margin-left: 10px;
  font-family: "Poppins" , sans-serif;
  color: green;
  font-size: 14px;
}
.product-title {
  font-size: 1.7rem;
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  color: #141f2b;
  margin: 1rem 0;
  font-family: "Poppins" , sans-serif;
  text-align: center;
}
.product-title::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  height: 1px;
  width: 100%;
  background: #12263a;
  text-align: center;
}
.product-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  background: #256eff;
  color: #fff;
  padding: 0 0.3rem;
  transition: all 0.5s ease;
}
.product-link:hover {
  opacity: 0.9;
}
.product-rating {
  color: #ffc107;
  margin-left: 8px;
}
.product-rating span {
  font-weight: 600;
  color: #252525;
  
}
.product-price {
  margin: 1rem 0;
  font-size: 1rem;
  font-weight: 700;
}
.product-price span {
  font-weight: 600;
  
}
.last-price span {
  color: grey;
  text-decoration: line-through;
}
.last-price {
  margin-bottom: 0px;
}
.new-price span {
  color: red;
  font-weight: 600;
  font-size: 16px;
  font-family: sans-serif;
}
.product-detail h2 {
  text-transform: capitalize;
  color: #286199;
  padding-bottom: 0.2rem;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
  font-family: "Poppins" , sans-serif;
}
.product-detail p {
  font-size: 0.9rem;
  padding: 0.3rem;
  opacity: 1;
 
}
.product-detail ul {
  margin: 1rem 0;
  font-size: 0.9rem;
  text-align: left;
  margin-left: 10px;
  
}
.product-detail ul li {
  margin: 0;
  background-size: 18px;
  padding-left: 0;
  margin: 0.4rem 0;
  font-weight: 600;
  opacity: 0.9;

}
.product-detail ul li span {
  font-weight: 400;
  font-family: "Poppins" , sans-serif;
}
.purchase-info {
  margin: 1.5rem 0;
}
.purchase-info input,
.purchase-info .btn {
  border: 1.5px solid #ddd;
  border-radius: 0px;
  text-align: center;
  padding: 0.45rem 0.8rem;
  outline: 0;
  margin-right: 0rem;
  margin-bottom: 0;
  width: 200px;
  font-weight: 1000;
  font-family: "Poppins" , sans-serif;
}
.purchase-info .btn:hover {
  transform: scale(1.1);
  transition: all 0.5s ease;
}
.purchase-info input {
  width: 60px;
}
.purchase-info .btn {
  cursor: pointer;
  color: #fff;
}
.purchase-info .btn:first-of-type {
  background: #8aaef7;
}
.purchase-info .btn:last-of-type {
  background: #2b92bb;
}
.purchase-info .btn:hover {
  opacity: 0.9;
}
.social-links {
  display: flex;
  align-items: center;
}
.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #000;
  border: 1px solid #000;
  margin: 0 0.2rem;
  border-radius: 50%;

  font-size: 0.8rem;
  transition: all 0.5s ease;
}
.social-links a:hover {
  background: #000;
  border-color: transparent;
  color: #fff;
}

@media screen and (min-width: 1024px) {
  .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
  .card-wrapper {
    /* height:100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-imgs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .product-content {
    padding-top: 0;
  }
}

.quantity {
  font-size: 25px;

  padding: 2px;
  border-radius: 50%;
  width: 40px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.bottom-menu {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 20px;
}
.product-accordian {
  display: none;
}

@media (max-width: 600px) {
  .img-showcase {
    height: 300px;
  }
  .product-title {
    font-size: 21px;
  }
  .bottom-menu {
    display: none;
  }
  .product-accordian {
    display: block;
  }
}

.relatedh2 {
  text-align: left;
  padding-left: 40px;
  padding-top: 20px;
  font-size: 25px;
  font-family: sans-serif;
  text-align: center;
  font-weight: 600;
}
.qualityPromise {
  list-style: disc !important;
  text-align: left !important;
}
.termsheading {
  text-align: left !important;
  font-size: 15px;
}

.terms ul li {
  font-size: 14px;
  text-align: left !important;
}
.terms ul {
  margin-left: 30px;
  text-align: left !important;
}
.accordianitems li {
  font-size: 12px;
  list-style: disc !important;
  text-align: left !important;
}
.accordianitems {
  margin-left: 10px;
  text-align: left !important;
}

.accodidanHeading {
  text-align: left !important;
  font-size: 13px;
}
.related_products{
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 3%;
  margin-bottom: 2%;
}