@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap");

.checkout-item {
  max-width: 600px;
  display: flex;
  flex-direction: row;
  background-color: white;
  margin: 10px;
}

.checkout-heading {
  font-family: "Poppins", sans-serif;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 2%;
  font-size: 20px;
  color: rgb(18, 41, 33);
  font-weight: 600;
  font-size: 25px;
  border-bottom: 1px solid grey;
}
.checkout-description h6 {
  font-family: "Poppins", sans-serif;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 2%;
  font-size: 20px;
  color: rgb(67, 68, 68);
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.checkout-image {
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkout-image img {
  width: 100%;
}

.checkout-description {
  width: 100%;
  margin-left: 20px;
}

.checkout-mid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkout-mid span {
  font-family: sans-serif;
  margin-right: 16px;
  cursor: pointer;
  color: grey;
  font-weight: 600;
}

.checkout-mid span:hover {
  color: black;
}

.checkout-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkout-quantity span {
  margin: 0 10px;
  padding: 0;
}

.qt-icon {
  font-size: 20px;
  cursor: pointer;
}

.qt-icon:hover {
  transform: scale(1.2);
}

.checkout-price {
  display: flex;
  flex-direction: column;
}

.checkout-price span {
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.checkout-price .stock {
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: green;
}

@media (max-width: 600px) {
  .checkout-item {
    margin: 3px;
  }

  .checkout-heading {
    font-size: 20px;
  }
  .checkout-description h6 {
    font-size: 16px;
  }

  .checkout-price span {
    font-size: 13px;
  }

  .checkout-mid span {
    font-size: 13px;
  }

  .checkout-quantity span {
    margin: 0 3px;
  }
}
