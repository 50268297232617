.single-seater
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}  
.single-seater h2
{
    border-bottom: 1px solid rgb(172, 168, 168);
    margin-left: 20;
    font-family: "Poppins" , sans-serif;
    color: rgb(35, 48, 49);
    font-weight: 600;
}
.top-cat-page
{
    margin-top: 20px;
}

.bottom-cat-page img
{
    width: 252px;
    height: 300px;
    margin: 6px;
    object-fit: contain;
    justify-self: flex-start;
}
.top-cat-page p
{
    width: 50%;
justify-self: center;
padding-left: 60px;
text-align: left;
font-family: "Poppins" , sans-serif;
font-weight: 300;
}
.bottom-cat-page
{ 
    width: 100% ;
    margin-top: 0px;
    margin-left: 0px;
}

@media (max-width:600px)
{
    .top-cat-page p
    {
        width: 100%;
        padding-left: 5px;
        font-family: "Poppins" , sans-serif;
font-weight: 300;
       
       
    }
    .single-seater h2 
    {
        font-size: 24px;
        text-align: center;
        font-weight: 600;
        border-bottom: 1px solid rgb(179, 179, 179);
        font-family: "Poppins" , sans-serif;
        font-weight: 600;
    }
    .top-cat-page p 
    {
        font-size: 12px ;
        
    }


}

.living-room-1
{
   width: 80%;
   display: flex;
   padding-left: 100px;
   padding-right: 100px;
   justify-content: center;
   align-self: center;
   flex-wrap: wrap;

  
}

.new-card-wrapper
{
    border: 2px solid rgb(235, 231, 231);
    width: 250px;
    height: 360px;
    margin-left: 10px;
    margin: 5px;
    background-color: white;
    box-shadow: 2px 2px 2px 0px rgba(0.2, 0.2, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition-duration: 0.6s;

}
@media (min-width:1200px)
{
.new-card-wrapper:hover{
    transform: translateY(-5px);
    box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
}
.new-card-wrapper img{
    object-fit: contain;
    width: 233px;
    height: 200px;
    

  
}
.new-card-wrapper span , del
{
    font-size: 10px;
    font-family: "Poppins" , sans-serif;
}
.new-card-wrapper h5
{
    font-size: 13px;
    font-weight: 600;
   margin-left: 2px;
   margin-right: 2px;
   font-family: "Poppins" , sans-serif;
}
.offer-text-cat 
{  background-color: rgba(224, 34, 34, 1);
    width: 50px;
    color: white;
    position: relative;
    top: 0;
    z-index: 2;
    margin-bottom: -30px;
    height: 30px;
   
    font-family: sans-serif;

}
.offer-text-cat span
{
    font-family:  sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-family: "Poppins" , sans-serif;
}


@media (max-width:600px)
{
    .bottom-cat-page
    {
    
       margin-left: 0px;
       margin-right: 0;

    }
    .living-room-1
    {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
}
.add-to-btn
{
    font-size:  14px !important;
    background-color: #3c6a9e !important;
}