.home1-body
{  width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
   margin-bottom: 0px;
   /* margin-top: -70px; */
  
 color: white;
background-color: rgb(31, 37, 56);
  height: fit-content;
  background-repeat: no-repeat;
  background-size: auto ;
  padding: 40px;
}
.home1-right img
{
    object-fit: contain;
    

}
.home1-left
{
width: 30%;

/* margin-top: -46%; */

  padding-bottom: 40px;
}
.home1-right
{
    /* margin-top: -46%; */
  
}
.home1-heading
{  
   
    font-size: 22px;
    border-bottom: 1px solid black;
    margin-bottom: 25px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin-left: 10px;
}

.home1-p
{
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 30px;
    text-align: left;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    padding-left: 0px;

}
@media (max-width:815px)
{
   .home1-body
   {
       margin-top: 0px;
       padding: 10px;

   }
    .home1-left
    {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
      

    }
    .home1-p
    {
        padding:0px;
        font-size: 12px;
    }
    .home1-right img 
    {
        padding-top: 0px;
    }
    .home1-banner
    {
        height: 600px;
        object-fit: cover;
    }
   
}