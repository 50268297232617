@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@500;600&display=swap");

.navbar3-body {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 60px;
  position: sticky !important;
  top: 0;
  z-index: 3;
  background-color: rgb(31, 37, 56);
  
  
}
.navbar3-body span {
  font-size: 16px;
  color:white;
  font-family: "Barlow Semi Condensed", sans-serif;
  text-decoration: none;

}
.navbar3-body span:hover {

  cursor: pointer;
  border-bottom: 2px solid white;
}
.hr-bottom {
  margin-top: 0px;
  border: 1px solid rgb(236, 236, 236);
  margin-bottom: 0px;
}
.search-bar-bottom {
  display: none;

  justify-self: center;
  margin-right: 25px;
}
.search-input-bottom {
  height: 34px;
  border: 1px solid rgb(142 142 142);
  width: 80%;
  text-align: center;
  border-radius: 5px;
}
.search-icon-bottom {
  padding: 4px;
  margin-left: 0px;
  align-self: center;
  position: relative;
  background-color: rgb(180, 211, 80);
  margin-top: 0px;
  transform: scale(1.46);
  border-radius: 3px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar3-body {
    display: none;
  }
  .search-bar-bottom {
    display: flex;
    justify-content: center;
  }
}
