.navbar1-body
{
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content:space-evenly;
    flex-wrap: nowrap;

   
}
.buyfromamazon{
    text-decoration: none;
    color: #000000;
}
.amazon a:hover{
    text-decoration: none;
}
.amazon-btn Button
{
   height: 25px;

  
   background-color: red !important;
}
.topbar-left .MuiButton-label
{
    font-family: inherit !important;
    font-size: 14px;
 
}

.icon
{
    font-size: 21px;
    margin-left: 5px;
    align-items: center;

}
.social-icons a
{
   color : rgb(146, 146, 146);
   font-size: 21px;
   margin: 1px;
}

.topbar-right
{
    display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}
.topbar-left
{
    display: flex;
    flex: 0.8;
    align-items: center;
    flex-wrap: nowrap;
   


}
.topbar-span span{
    margin-right: 8px;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif 
    

}
.topbar-span span:hover
{
    border-bottom: 2px solid black;
    cursor: pointer;
}
.hov-icon:hover
{
    transform: scale(1.15);
}

@media screen and (max-width:800px) {
    .topbar-right
    {
        display: none;
    }
    .topbar-left
    {
        justify-content: center;
    }
    .amazon-btn Button
    {
      
        font-size: 11px;
    }
    .topbar-left .MuiButton-label
    {
        font-size: 13px;
    }
    
}
.topbar-hr{
    border: 1px solid rgb(236, 236, 236);
}
