.aboutus__main{
    display: flex;
   

    font-family: "Mr Eaves XL Modern", sans-serif;
    justify-content:center;
  font-family: 'Poppins' , sans-serif;

}

.aboutus__left{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border:  1px solid #F4F4F4;


}

.aboutus__right{
    max-width: 32%;
}
.aboutus_left_first{
    display: flex;
    flex-direction: column;
}

.aboutus_left_first h4{
 
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
}
.aboutus_left_first p{
   
    font-size: 12px;
    color: #595857;
    text-align: justify;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
}

.aboutus_left_second{
    display: flex;
    justify-content: space-around;
   
    
}


.our_product_left h4{
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
    font-weight: bold;

}
.our_product_left img{
    min-width: 100px;
    max-width: 244px;

}
.our_product_right h4{
    font-size: 15px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    padding: 8px 0 4px 0;
    font-family: 'Poppins' , sans-serif;


}
.our_product_right p{
  
    font-size: 12px;
    color: #595857;
    text-align: justify;
    font-weight: normal;
    line-height: 1.5;
    padding: 8px 0 4px 0;
    font-family: 'Poppins' , sans-serif;



}
.our_product_right ul{
    margin-left: 0px;
}
.our_product_right ul li{
    
    font-size: 12px;
    color: #595857;
    
    font-weight: normal;
    line-height: 1;
    padding: 8px 0 4px 0;


}




@media screen and (max-width:600px){
    .aboutus__main{
       
        flex-direction:column;
        padding: 28px;
        

    }
    .aboutus__left{
        border: none;
        
    }
    
    .aboutus__right{
       
        overflow: hidden;
        max-width: 100%;
        
    }
    .aboutus__right_ul{
        visibility: hidden;
    }
    
    .aboutus_left_second{
        display: flex;
        flex-direction: column;
    }
    .aboutusimage img{
        width: 100%;
       
    }

}
@media screen and (min-width: 603px){
    .aboutus__main{
       
       
        padding: 28px;
        margin-top: 10px;
        margin-bottom: 10px;
        

    }
    .aboutus__left{
       padding: 0 64px;
       flex: 0.80;
    }
    
    .aboutus__right{
        color: red;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
     
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .aboutus__right ul li{
       
        margin-right: 23px;
        font-size: 14px;
        padding-top: 5px;
        
    } 
    
    .aboutus_left_second{
        display: flex;
        flex-direction: row;
    }
    .aboutus_left_first h4{
        margin: 10px;

        
    }
    .our_product_right{
        margin-top: 10px;
        margin-left: 50px;
    }
    .aboutus_left_first p{
       margin: 10px;
        
    }
    .our_product_right h4{
        margin: 10px;
    
    
    }
    .our_product_right ul{
      
        margin: 18px;
     
     
     }
    .our_product_right p{
      
       margin:10px;
    
    
    }
    

}