.navbar2-body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
  

 
}
.logo img {
  height: 46px;
  width: 233px;

}
.search-bar {
  display: flex;
  flex: 0.7;
  justify-self: center;
  margin-right: 5px;
}
.search-input {
  height: 36px;
  border: 2px solid rgb(148, 148, 146);
  width: 80%;
  text-align: center;
  margin-left: 40px;
  border-radius: 5px;
}
.search-icon {
  padding: 3px;
  margin-left: 0px;
  align-self: center;
  position: relative;
  background-color: rgb(148, 148, 146);
  margin-top: 0px;
  transform: scale(1.53);
  border-radius: 3px;
  cursor: pointer;
  color: white;

}
.navbarmid-right {
  display: flex;
  justify-content: space-between;
  width: 10%;
}
.logo-mid {
  transform: scale(1.8);
}

.hr-mid {
  border: 1px solid rgb(236, 236, 236);
  margin-top: 20px;
}

/* Dropdown Button */
.dropbtn {
  cursor: pointer;
  margin: 0 30px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  top: 35px;
  right: 15px;
  width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
  cursor: pointer;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #ddd;
}

.user-details {
  font-size: 12px;
  padding: 5px 16px;
}

.menu-btn {
  display: none;
}
.cart-icon span {
  font-size: 14px;
  font-style: italic;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 500;

}

.amazon
{
  background-color: rgb(236, 207, 60) !important;
  color:black !important ;
  font-weight: 1000 !important;
  font-family: 'Farro', sans-serif  !important;
  font-size: 10px !important;
}




@media screen and (max-width: 768px) {
  .navbar2-body {
    justify-content: space-around;
    margin-top: 10px;

  }

  .logo img {
    width: 190px;
    height: 35px;

    justify-content: center;
    margin-right: 15px;
  }
  .search-bar {
    display: none;
  }
  .logo-mid {
    transform: scale(1.5);
    margin-right: 2px;
  }
  .menu-btn {
    display: block;
  }
  .dropdown {
    display: none;
  }
  .cart-icon span {
    font-size: 11px;
  }
  .hr-mid {
    margin-top: 10px;
  }
  .MuiBadge-root {
    margin-right: 9px;
  }
}
