.card-body {
    background-color: white;
    border-radius: 10px;
    width: 240px;
    height: 410px;
    margin: 7px;
    position: relative;

 
    
    padding: 0;

    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
  }
  
  @media (min-width:1200px)
  {
  .card-body:hover
  {
    transform: translateY(-6px);
    transform: translateZ(20);
    transition-duration: 0.7s;
    box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
  .card-body img {
  
    width: 270px;
    height: 253px;
    object-fit: contain;
border-radius: 15px;
   
  }
  
  .card1-top {
    background-color: transparent;
  
    width: 100%;
    overflow: hidden;
    text-align: center;
  
    border-radius: 10px;
  
  }
  
  .card-bottom {

    min-height: 45%;
  
    min-width: 98%;
    max-width: 98%;
    margin-top: 0px;
    overflow-wrap: break-word;
    text-align: center;
    height: 40px;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  }
  .product-name 
  {
      color: rgb(110, 109, 109);
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 1000;
      font-family: "Poppins" , sans-serif;
      padding-left: 4px;
  }
  .top-heading
  {
      font-size: 9px;
      font-family: "Poppins" , sans-serif;

  }
.price-cut
{
    font-size: 11px;
    
    color: grey;
}

.add-to-cart-btn
{
    background-color: rgb(60, 133, 175) !important;
    transform: scale(0.9);
    font-family: "Poppins" , sans-serif !important;

    
  
    
}
.price-card
{
  color: red;
}
.see-details-btn
{
  margin-top: 3px;
  font-size: 10px;
  transform: scale(0.8);
  cursor: pointer;
  

}
.rating-star
{
  font-size: 11px;
  color: orange;
}
@media(max-width:600px) 
{
  .card-body{
    width:200px;
    height: 330px;
  }
  
  .card-body img
  {
   height: 170px;
  }
  .card-bottom{
    margin-top: 0px;
  }
  .add-to-cart-btn
  {
    transform: scale(0.75);
  }
  
}

.offer-text 
{
  position: absolute;
  top: 0px;

  background-color: rgba(224, 34, 34, 0.801);
  border-radius: 0%;
 padding: 10px;
 color: white;
 font-weight: 600;
 font-family: "Poppins" , sans-serif;

}
.offer-text span
{
  font-family: "Poppins" , sans-serif;
}