.container {
    position: relative;
    width: 100%;
    max-width: 220px;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 20px;
    margin-left: 0px;
    margin-bottom: 5px;
    
    box-shadow: 2px 2px 2px 0px rgba(0.2, 0.2, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.6s;

}
@media (min-width:1200px) {
  

.container:hover
{
  transform: translateY(-10px);
  transform: translateZ(10);
  transition-duration: 0.6s;
  box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
}

  
  
  .image {
    display: flex;
  width: 220px;
height: 296px;
object-fit: cover;

  }
  
  .overlay {
    position: absolute; 
    bottom: 0; 
    background: rgb(49, 49, 48);
   
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0.6;
    color: white;
    
   
    text-align: center;

 
  }
  .overlay h5
  {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 3px;
  }
  
  .overlay span{
      font-size: 10px;
  }

  .container:hover .overlay {
    opacity: 1;
  }