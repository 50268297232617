@import url('https://fonts.googleapis.com/css2?family=Play&display=swap');
  
  #title-Tag-Line {
    font-size: 20px;
  }
  /* .card-item__bg{
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: block;
    position: relative;
    margin: 30px auto;
    transform: translate(0px, 50px);
    z-index: 5;
  } */
  
  /* form animation starts */
  .form {
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 5px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 0;
    right: 0;
    color: white;
    
    border-top: 5px solid #f59a9a;
  /*   z-index: 1; */
    animation: bounce 1.5s infinite;
    background-color: #537895;
background-image: linear-gradient(315deg, #537895 0%, #09203f 74%);
  }
  ::-webkit-input-placeholder {
    font-size: 1.3em;
  }

  
  .title{
    display: block;
    font-family: sans-serif;
    margin: 10px auto 5px;
    width: 300px;
  }
  .termsConditions{
    margin: 0 auto 5px 80px;
  }
  
  .pageTitle{
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }
  .secondaryTitle{
    color: rgb(207, 207, 207);
    text-align: center;
  }
  
  .name {
    background-color: #ebebeb;
    color: black;
  }
  .name:hover {
    border-bottom: 5px solid #0e3721;
    height: 30px;
    width: 380px;
    transition: ease 0.5s;
  }
  
  .email {
    background-color: #ebebeb;
    height: 2em;
  }
  
  .email:hover {
    border-bottom: 5px solid rgb(143, 221, 143);
    height: 30px;
    width: 380px;
    transition: ease 0.5s;
  }
  
  .message {
    background-color: #ebebeb;
    overflow: hidden;
    height: 10rem;
  }
  
  .message:hover {
    border-bottom: 5px solid #0e3721;
    height: 12em;
    width: 380px;
    transition: ease 0.5s;
  }
  
  .formEntry {
    display: block;
    margin: 30px auto;
    min-width: 274px;
    padding: 10px;
    border-radius: 2px;
    border: none;
    transition: all 0.5s ease 0s;
  }
  
  .submit {
    width: 200px;
    color: white;
    background-color:rgb(180,211,80);
    font-size: 20px;
  }
  
  .submit:hover {
    box-shadow: 15px 15px 15px 5px rgba(78, 72, 77, 0.219);
    transform: translateY(-3px);
    width: 300px;
    border-top: 5px solid #0e3750;
    border-radius: 0%;
  }
  
  @keyframes bounce {
    0% {
      tranform: translate(0, 4px);
    }
    50% {
      transform: translate(0, 8px);
    }
  } 
  
  