.home2-body
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
}
.home2-top
{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 10px;
}
.home2-bottom
{
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-left: 10px;
}
.home2-body h3
{

    margin-bottom: 20px;
    border-bottom: 1px solid rgb(218, 217, 217);
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #03363d;

}