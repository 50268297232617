.review__main {
  margin: 0px;
  padding: 0px;

  box-sizing: border-box;
  background-color:  rgb(31, 37, 56);
  padding-bottom: 30px;
 
}
a {
  text-decoration: none;
}
#testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  
}
.testimonial-heading {
  letter-spacing: 1px;
  margin: 0px 0px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 800;
}
.testimonial-heading h1 {
  font-size: 2.2rem;
  font-weight: 500;
  background-color: rgb(31, 37, 56);
  color: #ffffff;
  padding: 11px 15px;
  margin-top: 24px;
  padding-top: 16px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 800;
}
.testimonial-heading span {
  font-size: 1.3rem;
  color: white;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.testimonial-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  width: 100%;
  
  
}
.testimonial-box {
  width: 500px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  margin: 15px;
  cursor: pointer;
  height: 240px;
  border-radius: 10px;
  background-color: #fd5f6d;

  
}
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.profile {
  display: flex;
  align-items: center;
}
.name-user {
  display: flex;
  flex-direction: column;
}
.name-user strong {
  color:white;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  font-weight: 800;
  font-family: 'Poppins' , sans-serif;
}
.name-user span {
  color:white;
  font-size: 0.8rem;
}
.reviews {
  color: white;
  font-size: 17px;
}
.box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.client-comment p {
  font-size: 0.9rem;
  color: white;
  font-weight: 500;
  font-family: 'Poppins' , sans-serif;
}
.testimonial-box:hover {
  transform: translateY(-10px);
  transition: all ease 0.3s;
}

@media (max-width: 1060px) {
  .testimonial-box {
    width: 45%;
    padding: 10px;
  }
}
@media (max-width: 790px) {
  .testimonial-box {
    width: 100%;
    height: fit-content;
  }
  .testimonial-heading h1 {
    font-size: 21px;
    padding: 0;
  }
}
@media (max-width: 340px) {
  .box-top {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .reviews {
    margin-top: 10px;
  }
}
::selection {
  color: #ffffff;
  background-color: #252525;
}
