

.privacy__main{
  margin: 20px auto;
  
  width: 100%;
  padding: 32px;
  
 
  
}
.privacy__main h1{
    font-size: 20px;
    color: #ED7745;
  
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
    
}
.privacy__main h3{
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
    
}
.privacy__main h5{
    font-size: 17px;
    color: #ED7745;
    font-weight: bold;
    padding: 8px 0 4px 0;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;
}

.privacy__p{

    font-size: 12px;
    color: #595857;
    text-align: justify;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;

}

.privacy__second ol li{
   
    font-size: 12px;
    color: #595857;
    text-align: justify;
    padding: 8px 0 4px 0;
    font-weight: normal;
    line-height: 1.5;
    font-family: 'Poppins' , sans-serif;



}
.privacy__second ol{
    padding-left: 20px;
}

@media screen and ( min-width:600px) {
    .privacy__main{
      
        padding: 0 130px;
        width: 78%;
        margin-top:70px;
        

      }
      .privacy__second ol{
          padding-left: 20px;
      }
      .privacy__second ol li{
    
        font-size: 12px;
        color: #595857;
        text-align: justify;
        padding: 8px 0 4px 0;
        font-weight: normal;
        line-height: 1.5;
      
    
    
    }
    
}