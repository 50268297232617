@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body{
	line-height: 1.5;
	font-family: "Mr Eaves XL Modern", sans-serif

}

.footer__body{
	width: 100%;
	bottom: 0;
}
.footer{
	margin:0;
	padding:0;
	box-sizing: content-box !important;
	
}

.container__footer{
	max-width: 1170px;
	margin:auto;
}
.row__footer{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
	margin-left: 0px;
}
.footer{
	background-image: url("footer.png");
    padding: 70px 0;

	
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 13px;
	color: white;
	font-family: "Mr Eaves XL Modern", sans-serif;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 600;
	position: relative;
	text-align: left;
	margin-right: 30px;
}



.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}

.footer-col ul li a{
	font-size: 11px;
	text-transform: capitalize;
	color:white;
	text-decoration: none;
	font-weight: 400;
	text-align: left;
	display: block;
	transition: all 0.3s ease;
	font-family: "Mr Eaves XL Modern", sans-serif;
	
}
.footer-col ul li a:hover{
	color:white;

}
.footer-col .social-links a{
    text-decoration: none;
}

.footer-col .social-links a img{
   
	display: inline-block;
	height: 40px;
	width: 40px;
	margin:0 10px 10px 0;	text-align: center;
	border-radius: 50%;
	
	transition: all 0.5s ease;
}


.footer_p{
	color:white;
   font-size: 11px;
	font-family: "Mr Eaves XL Modern", sans-serif;


}
.footer__p{

     font-size: 14px;
	 border-bottom: 1px solid orange;
	 width:120px;
 }
.city__p{
    color:white;
    font-size: 11px;
	font-family: "Mr Eaves XL Modern", sans-serif;

}
.payment__image{
	width: 247px;
    height: 160px;
}

.footer__logo{
	height: 20%;
	width: 100%;
}
 

/*responsive*/
@media(max-width: 768px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}


.need-help-ul
{
	margin-left: 0px;
}

.all-rights
{
	margin-right: 0px;
	border-bottom: 1px solid orange;
}
ul{
	padding-inline-start: 0px !important;

}
.copy
{
	font-size: 12px !important;
	color: white;
	text-align: center;
}


.social-icons-footer a
{
   color : rgb(119, 199, 252);
   font-size: 21px;
   margin: 3px;
}
.social-icons-footer  a:hover
{
	transform: scale(1.4);
}


.youtube
{
	color: red;
}
.facebook
{
color: rgb(57, 159, 255);
}
.instagram
{
	color: white;
}
.linkedin
{
	color: rgb(73, 158, 255);
}
.google 
{
	color: white;
}
.pinterest
{
	color: rgb(255, 0, 85);
}