@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Play&family=Quicksand&display=swap");

.online-offline {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.styles_row-content__QOGZd {
  height: 100px;
}
.styles_faq-row-wrapper__3vA1D h2 {
  margin: auto;
  color: #f9821c;
width: 100%;
  font-size: 29px;
  font-family: 'Poppins' , sans-serif !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 10px 0px;
  
  display: flex;
  flex-wrap: wrap;
  color: black;
  font-size: 13px;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  font-family: 'Poppins' , sans-serif !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: #969696;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 12px;
  padding: 4px;
}
styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  padding-right: 0em;
}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1px 13px;
  border-bottom: 1px solid #ccc;
}

.styles_faq-row-wrapper__3vA1D {
  background-color: #f4f5f7;
  font-family: "Fira Sans", sans-serif;
}

@media (max-width:600px) {
  .online-offline
  {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }
  
}