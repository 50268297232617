.payment__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  padding-left: 50px;
  padding-right: 50px;
  
}
.payment__p_h {
 
  font-size: 14px;
  color: #595857;
  text-align: justify;
  padding: 8px 0 4px 0;
  padding-left: 20px;
  font-weight: 300;
  max-width: 85%;
  line-height: normal;
  color: #ed7745;
}
.payment__h {
  text-align: center;
 margin-bottom: 25px;
  margin-top: 40px;
  font-size: 24px;
  color: #ed7745;
 padding: 8px 0 4px 0;
  font-weight: 600;
  line-height: 1.5;
}

.payment-policy h1 {
    font-weight: 600;
    line-height: 1.5;
    font-size: 23px;
  margin-bottom: 25px;
  color: #ed7745;
  font-family: 'Poppins' , sans-serif;
}
.payment-policy h2{
  font-weight: 600;
    line-height: 1.5;
    font-size: 18px;
  margin-bottom: 25px;
  color: #ed7745;
  font-family: 'Poppins' , sans-serif;
}

.payment__first {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 42px 40px;
}
.payment__second {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1px;

  margin: 42px 40px;
}
.payment__third {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment__fourth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment__fifth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment__six {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1px;

  margin: 42px 40px;
}
.payment-img {
  width: 95%;
  padding-top: 26px;
}
.payment__p {
  
  font-size: 12px;
  color: #595857;
  text-align: justify;
  padding: 1px 0 4px 0;
  padding-left: 20px;
  font-weight: 300;
  max-width: 85%;
  line-height: normal;
  font-family: "Poppins", sans-serif;
  
}

.p_f_f {
  flex: 0.5;
}
.p_f_s {
  flex: 0.5;
}

@media screen and (min-width: 1100px) {
  .payment__main {
    padding-left: 140px;
    padding-right: 140px;
  }
}
@media screen and (max-width: 600px) {
  .payment__main {
    padding: 0px;
  }

  .payment__first {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 42px 40px;
  }
  .payment__second {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__third {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__fourth {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__fifth {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
  .payment__six {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px;

    margin: 42px 40px;
  }
}
