.MuiTypography-body1
{
  font-family: 'Poppins' , sans-serif !important;
  font-weight: 600 !important;
}
.MuiDrawer-paperAnchorLeft 
{
  background-color: #b8c6db !important;
background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%) !important;

}
.makeStyles-list-1

{
  background-color: #b8c6db !important;
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%) !important;
  
}